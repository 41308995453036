import React, { forwardRef, useEffect, useRef, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import MenuIcon from "@mui/icons-material/Menu";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  Alert,
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import MaterialTable from '@material-table/core';
import CloseIcon from "@mui/icons-material/Close";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteIcon from "@mui/icons-material/Delete";
import AppBarCommon from "../AppBarCommon";
import "./Devices.css";
import { copyTextToClipboard } from "../../utils/helper"

const Devices = () => {
  const defaultMaterialTheme = createTheme();
  const tableRef = useRef();
  const drawerWidth = 260;
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([])
  const [snackbarMessage, setSnackBarMessage] = useState({
    color: "",
    severity: "",
    message: "",
  });
  let url;
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  useEffect(() => {
    getDevices()
  }, []);

  const getDevices = async () => {
    try {
      const deviceResponse = await axiosPrivate.get(`/devices/v1?page=1&limit=10`, {
        signal: controller.signal,
      });
      setDevices(deviceResponse.data.devices)
      // setTotalCount(userResponse.data.page_metadata.total_count);
      return deviceResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { title: "Device Name", field: "coreDeviceThingName" },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Public IP",
      field: "meta_info.public_ip",
      render: (rowData) => (
        rowData.meta_info?.public_ip? 
        <>
          <span>{rowData.meta_info.public_ip}</span>
          <ContentCopy
            style={{ fill: "grey", cursor: "pointer" }}
            onClick={(ev) => {
              let text = rowData.meta_info.public_ip
              copyTextToClipboard(text, ()=>{
                setSnackBarMessage({
                  message: "Text copied to clipboard",
                  color: "#3cb371",
                  severity: "success",
                });
              })
            }}
          />
        </>:
        "-"
      )
    },
    {
      title: "Local IP",
      field: "meta_info.local_ip",
      render: (rowData) => (
        rowData.meta_info?.local_ip? 
        <>
          <span>{rowData.meta_info.local_ip}</span>
          <ContentCopy
            style={{ fill: "grey", cursor: "pointer" }}
            onClick={(ev) => {
              let text = rowData.meta_info.local_ip
              copyTextToClipboard(text, ()=>{
                setSnackBarMessage({
                  message: "Text copied to clipboard",
                  color: "#3cb371",
                  severity: "success",
                });
              })
            }}
          />
        </>:
        "-"
      )
    },
    {
      title: "Last Updated Date",
      field: "lastStatusUpdateTimestamp",
    },
  ];

  return (
    <div>
      <AppBarCommon
        name="Devices"
        style={{ position: "", top: 0, width: "100%", zIndex: 10 }}
      />

      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ marginTop: "20px", position: "relative" }}
          title=""
          //   key={muiTableKey}
          // icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          options={{
            debounceInterval: 700,
            padding: "dense",
            // filtering: false,
            pageSize: 10,
            sorting: true,
            thirdSortClick: false,
            searchFieldStyle: {
              minWidth: "420px",
              paddingRight: "30px",
            },
          }}
          data={devices}
        />
      </ThemeProvider>
      <Snackbar
          style={{ marginLeft: "100px" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={snackbarMessage.message != ""}
          autoHideDuration={2000}
          onClose={() =>
            setSnackBarMessage({ color: "", message: "", severity: "" })
          }
        >
          <Alert
            onClose={() =>
              setSnackBarMessage({ color: "", message: "", severity: "" })
            }
            severity={snackbarMessage.severity}
            sx={{
              width: "100%",
              background: snackbarMessage.color,
              color: "white",
            }}
            style={{ fontFamily: "Work Sans" }}
          >
            {snackbarMessage.message}
          </Alert>
        </Snackbar>

    </div>
  );
};

export default Devices;
