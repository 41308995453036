import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Chip, Stack } from "@mui/material";
import "./HomePage.css";
import AppBarCommon from "./AppBarCommon";
import Card from "./Card";
import { Bar } from "react-chartjs-2";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const HomePage = () => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  // const hasAdminRole = localStorage.getItem("user_name") === "admin";
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(
          "/defect-inspection/v1/overview_report"
        );

        const mapData = response.data.recent_events_report.dates;
        const colors = ["#294A50", "#49758d", "#dcde5d"];

        const chartDataSet = response.data.recent_events_report.sessions.map(
          (item, index) => {
            let color_code = colors[index % colors.length];
            return {
              label: item.name,
              data: item.event_counts,
              backgroundColor: color_code,
              borderColor: color_code,
              borderWidth: 1,
              barPercentage: 0.2,
            };
          }
        );
        setChartData({
          labels: mapData,
          datasets: chartDataSet,
        });
        setIsHomePage(true);
        setIsLoading(true);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <>
      <AppBarCommon name="Home" />
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "83%",
            padding: "0px 20px",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          <Card
            className="bar__chart"
          >
            <div style={{ position: "relative", height: "100%" }}>
              {/* <Toolbar>
                <Typography variant="h6">Recent events per order</Typography>
              </Toolbar> */}
              <Bar
                className="bar_chart_content"
                data={{
                  ...chartData,
                  datasets: chartData.datasets.map((dataset) => ({
                    ...dataset,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                  })),
                }}
                options={{
                  // responsive: false,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                    },
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: "Count",
                        color: "black",
                      },
                      grid: {
                        display: true, // Display the x-axis grid lines
                        borderDash: [3, 3], // Make the x-axis lines dotted
                        color: "lightgray",
                      },
                      ticks: {
                        color: "black", // Set x-axis label (tick) color to black
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Date",
                        color: "black",
                      },
                      grid: {
                        display: false, // Disable the y-axis grid lines
                        color: "#777",
                      },
                      ticks: {
                        color: "black", // Set x-axis label (tick) color to black
                      },
                    },
                  },
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  position: "absolute",
                  top: 12,
                  // right: 30,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                }}
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {chartData?.datasets?.map((dataset, index) => (
                  <Chip
                    key={index}
                    label={dataset.label}
                    avatar={
                      <div
                        style={{
                          backgroundColor: dataset.backgroundColor,
                          borderRadius: "50%",
                          width: "12px",
                          height: "12px",
                        }}
                      />
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "black",
                      marginLeft: "8px",
                      marginBottom: "8px",
                      marginRight: "8px",
                      border: "1px solid #294A50",
                    }}
                  />
                ))}
              </Stack>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default HomePage;
