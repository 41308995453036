import React, { forwardRef, useEffect, useRef, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import MaterialTable from '@material-table/core';
import CloseIcon from "@mui/icons-material/Close";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Profile.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutlineIcon {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterAltIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeftIcon {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardIcon {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <RemoveIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumnIcon {...props} ref={ref} />
  )),
};

const Profile = () => {
  const defaultMaterialTheme = createTheme();
  const tableRef = useRef();
  const drawerWidth = 260;
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [dimensions, setDimensions] = useState([]);
  const [length, setLength] = useState();
  const [orientation, setOrientation] = useState();
  const [profileTitle, setProfileTitle] = useState("");
  const [dimensionTitle, setDimensionTitle] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enteredProfileIsTouched, setEnteredProfileIsTouched] = useState(false);
  const enteredProfileIsValid = profileTitle.trim() !== "";
  const profileInputIsValid = !enteredProfileIsValid && enteredProfileIsTouched;
  const titleRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const canvasRef = useRef(null);
  const [linePoints, setLinePoints] = useState([
    { startX: 350, startY: 250, endX: 350, endY: 250 },
  ]);
  const [deletedId, setDeletedId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  const columns = [
    {
      title: "Profile Id",
      field: "profile_id",
      sorting: false,
      filtering: false,
    },
    {
      title: "Profile Name",
      field: "title",
      sorting: false,
      filtering: false,
    },
    {
      title: "Actions",
      field: "",
      sorting: false,
      filtering: false,
      render: (rowData) => (
        <IconButton
          onClick={() => {
            handleOpenModal(rowData.profile_id);
          }}
          style={{ width: "65px" }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  let url;
  const axiosPrivate = useAxiosPrivate();

  const controller = new AbortController();
  const getProfile = async (url) => {
    try {
      const userResponse = await axiosPrivate.get(url, {
        signal: controller.signal,
      });
      setTotalCount(userResponse.data.page_metadata.total_count);
      return userResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenEdit(false);
    setLength("");
    setProfileTitle("");
    setOrientation("");
    setDimensions([]);
    setLinePoints([{ startX: 350, startY: 250, endX: 350, endY: 250 }]);
  };

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setDeletedId(id);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeSnackbar = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const handleDelete = async (id, _e) => {
    setDeleteLoading(true);
    try {
      const token = localStorage.getItem("access_token");

      const response = await axiosPrivate.delete(`/profiles/v1/${id}`, token, {
        withCredentials: false,
      });
      setIsSnackbarOpen(true);
      handeleTableReload();
      setOpenModal(false);
      return response.data;
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDialog = (scrollType) => {
    setOpenDialog(true);
    setScroll(scrollType);
  };

  const handleEditOpen = (rowData, index) => {
    setSelectedRowIndex(index);
    setSelectedRowData(rowData);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleUpdate = () => {
    setDimensions((prevDimensions) =>
      prevDimensions.map((item, index) =>
        index === selectedRowIndex ? selectedRowData : item
      )
    );

    const updatedDimensions = dimensions.map((item, index) =>
      index === selectedRowIndex ? selectedRowData : item
    );
    const newLinePoints = calculateLinePoints(updatedDimensions);
    setLinePoints(newLinePoints);

    handleEditClose();
  };

  const calculateLinePoints = (updatedDimensions) => {
    const newLinePoints = [{ startX: 350, startY: 250, endX: 350, endY: 250 }];

    const scaleFactor = (5 * canvasRef.current.width) / 100;
    let lastPoint = newLinePoints[0];

    updatedDimensions.forEach((dimension) => {
      let newEndPointX, newEndPointY;

      switch (dimension.orientation) {
        case "Horizontal-positive":
          newEndPointX = lastPoint.endX + dimension.length * scaleFactor;
          newEndPointY = lastPoint.endY;
          break;
        case "Horizontal-negative":
          newEndPointX = lastPoint.endX - dimension.length * scaleFactor;
          newEndPointY = lastPoint.endY;
          break;
        case "Vertical-positive":
          newEndPointX = lastPoint.endX;
          newEndPointY = lastPoint.endY - dimension.length * scaleFactor;
          break;
        case "Vertical-negative":
          newEndPointX = lastPoint.endX;
          newEndPointY = lastPoint.endY + dimension.length * scaleFactor;
          break;
        default:
          break;
      }

      newLinePoints.push({
        startX: lastPoint.endX,
        startY: lastPoint.endY,
        endX: newEndPointX,
        endY: newEndPointY,
      });

      lastPoint = newLinePoints[newLinePoints.length - 1];
    });

    return newLinePoints;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    const drawLines = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      for (let i = 1; i < linePoints.length; i++) {
        const point = linePoints[i];
        ctx.beginPath();
        ctx.moveTo(linePoints[i - 1].endX, linePoints[i - 1].endY);
        ctx.lineTo(point.endX, point.endY);
        ctx.stroke();
      }
    };

    drawLines();

    return () => ctx.clearRect(0, 0, canvas.width, canvas.height);
  }, [linePoints]);

  const handeleTableReload = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  useEffect(() => {
    if (totalCount) {
      handeleTableReload();
    }
  }, [totalCount]);

  const profileInputBlurHandler = (e) => {
    setEnteredProfileIsTouched(true);
  };

  const inputFieldFocus = () => {
    setShowError(false);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const imageData = convertCanvasToImage();

    if (profileTitle.trim() === "" && dimensions.length === 0) {
      setErrorMessage("Title and dimensions cannot be empty.");
      setShowError(true);
      setIsLoading(false);
      return;
    }

    if (profileTitle.trim() === "") {
      setErrorMessage("Title cannot be empty.");
      setShowError(true);
      setIsLoading(false);
      return;
    }

    if (dimensions.length === 0) {
      setErrorMessage("Dimensions cannot be empty.");
      setShowError(true);
      setIsLoading(false);
      return;
    }

    const payload = {
      title: profileTitle,
      dimensions: dimensions,
      image: imageData,
    };

    // console.log("payload", payload);
    const access_token = localStorage.getItem("access_token");
    try {
      const response = await axiosPrivate.post("/profiles/v1", payload, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: false,
      });

      setOpenDialog(false);
      setOpenEdit(false);
      getProfile("/profiles/v1");
      setProfileTitle("");
      setLength("");
      setOrientation("");
      setDimensions([]);
      setLinePoints([{ startX: 350, startY: 250, endX: 350, endY: 250 }]);
      setIsLoading(false);
      setEnteredProfileIsTouched(true);
      if (!enteredProfileIsValid) {
        return;
      }
      setEnteredProfileIsTouched(false);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const addDimensionHandler = () => {
    let newLength = parseFloat(length.trim()).toFixed(2);

    let newEndPointX, newEndPointY;

    // let total_x = 0
    // let total_y = 0
    // for (let index = 0; index < linePoints.length; index++) {
    //   const linePoint = linePoints[index];
    //   total_x += linePoint.endX - linePoint.startX
    //   total_y += linePoint.endY - linePoint.startY
    // }
    // if (canvasRef.current.width<total_x) {

    // }

    const lastPoint = linePoints[linePoints.length - 1];
    const canvasWidth = canvasRef.current.width;
    const canvasHeight = canvasRef.current.height;

    const scaleFactor = (5 * canvasWidth) / 100;

    switch (orientation) {
      case "Horizontal-positive":
        newEndPointX = lastPoint.endX + newLength * scaleFactor;
        newEndPointY = lastPoint.endY;
        if (newEndPointX > canvasWidth) {
          newLength = (canvasWidth - lastPoint.endX) / scaleFactor;
          newEndPointX = canvasWidth;
        }
        break;
      case "Horizontal-negative":
        newEndPointX = lastPoint.endX - newLength * scaleFactor;
        newEndPointY = lastPoint.endY;
        if (newEndPointX < 0) {
          newLength = lastPoint.endX / scaleFactor;
          newEndPointX = 0;
        }
        break;
      case "Vertical-positive":
        newEndPointX = lastPoint.endX;
        newEndPointY = lastPoint.endY - newLength * scaleFactor;
        if (newEndPointY < 0) {
          newLength = lastPoint.endY / scaleFactor;
          newEndPointY = 0;
        }
        break;
      case "Vertical-negative":
        newEndPointX = lastPoint.endX;
        newEndPointY = lastPoint.endY + newLength * scaleFactor;
        if (newEndPointY > canvasHeight) {
          newLength = (canvasHeight - lastPoint.endY) / scaleFactor;
          newEndPointY = canvasHeight;
        }
        break;
      default:
        return;
    }

    setLinePoints([
      ...linePoints,
      {
        startX: lastPoint.endX,
        startY: lastPoint.endY,
        endX: newEndPointX,
        endY: newEndPointY,
      },
    ]);
    const newDimension = {
      title: dimensionTitle,
      length: newLength,
      orientation,
    };
    setDimensions((prevDimensions) => [...prevDimensions, newDimension]);
    setLength("");
    setOrientation("");
    setDimensionTitle("");
  };

  const convertCanvasToImage = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    return dataURL;
  };

  const deleteHandler = (indexToDelete) => {
    const updatedDimensions = dimensions.filter(
      (_, index) => index !== indexToDelete
    );
    setDimensions(updatedDimensions);
    const newLinePoints = calculateLinePoints(updatedDimensions);
    setLinePoints(newLinePoints);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        elevation={1}
        boxshadow={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ backgroundColor: "white" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "sans-serif",
                color: "rgb(94 89 89)",
                fontWeight: 600,
                fontSize: "18px",
              }}
              noWrap
              component="div"
            >
              Profile
            </Typography>
          </div>
          <Button
            style={{
              width: "150px",
              fontSize: "12px",
              borderRadius: "10px",
              backgroundColor: "#1976d2",
              color: "#fff",
            }}
            onClick={() => handleDialog()}
            variant="contained"
          >
            Add Profile
          </Button>
        </Toolbar>
      </AppBar>

      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ marginTop: "20px", position: "relative" }}
          title=""
          //   key={muiTableKey}
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          options={{
            debounceInterval: 700,
            padding: "dense",
            // filtering: false,
            pageSize: 10,
            sorting: true,
            thirdSortClick: false,
            searchFieldStyle: {
              minWidth: "420px",
              paddingRight: "30px",
            },
          }}
          data={(query) =>
            new Promise((resolve) => {
              url = "/profiles/v1?";
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              //url += "&search=" + query.search
              
              //Search 
              if(query.search){
                url += "&search=" + query.search;
                console.log(query.search);
              }

              const getData = () => {
                getProfile(url).then((data) => {
                  setTotalCount(data.page_metadata.total_count);
                  resolve({
                    data: data.profiles,
                    page: query.page,
                    totalCount: data.page_metadata.total_count,
                  });
                });
              };
              getData();
            })
          }
          detailPanel={(rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10%",
                  padding: "15px",
                }}
              >
                <div
                  className="table-container"
                  style={{ width: "50%", padding: "10px" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Length (inch)</th>
                        <th>Orientation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div className="scrollable-content">
                        {rowData.dimensions.map((data, index) => (
                          <tr key={index}>
                            <td>{data.title}</td>
                            <td>{data.length}</td>
                            <td>{data.orientation}</td>
                          </tr>
                        ))}
                      </div>
                    </tbody>
                  </table>
                </div>
                <div>
                  <img
                    src={rowData.image}
                    alt="Profile"
                    style={{
                      width: "300px",
                      height: "200px",
                      border: "1px solid #000",
                    }}
                  />
                </div>
              </div>
            );
          }}
          //Search Place Holder
          localization={{
            toolbar:{
              searchPlaceholder: "Search by Profile ID",
            }
          }}
        />
      </ThemeProvider>

      <Dialog
        open={openDialog}
        scroll={scroll}
        fullScreen
        // maxWidth="lg"
        // sx={{
        //   "& .MuiDialog-paper": {
        //     width: "100%",
        //     overflowX: "hidden",
        //     height: "500px",
        //   },
        // }}
      >
        <DialogTitle>
          Create Profile
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 3,
              height: "46px",
              width: "46px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <div style={{ display: "flex" }}> */}
        <DialogContent dividers={scroll === "paper"}>
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div style={{ width: "50%", paddingRight: "20px" }}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <TextField
                  ref={titleRef}
                  id="outlined-basic"
                  label="Title"
                  error={profileInputIsValid}
                  value={profileTitle}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  onFocus={inputFieldFocus}
                  onBlur={profileInputBlurHandler}
                  style={{ marginBottom: "20px", marginTop: "10px" }}
                  onChange={(e) => setProfileTitle(e.target.value)}
                />
                {profileInputIsValid && (
                  <p className="error-text">Title must not be empty.</p>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  Dimensions
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    gap: "20px",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "30%",
                      marginRight: "5px",
                    }}
                  >
                    <InputLabel>Title</InputLabel>
                    <OutlinedInput
                      label="Title"
                      value={dimensionTitle}
                      autoComplete="off"
                      onChange={(e) => setDimensionTitle(e.target.value)}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      width: "30%",
                      marginRight: "5px",
                    }}
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={length || " "}
                      autoComplete="off"
                      onChange={(e) => setLength(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">inch</InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  <FormControl
                    key={Math.random()}
                    sx={{
                      width: "56%",
                    }}
                  >
                    <InputLabel>Orientation</InputLabel>
                    <Select
                      value={orientation}
                      onChange={(e) => setOrientation(e.target.value)}
                    >
                      <MenuItem value="Horizontal-negative">
                        Horizontal-negative
                      </MenuItem>
                      <MenuItem value="Horizontal-positive">
                        Horizontal-positive
                      </MenuItem>
                      <MenuItem value="Vertical-positive">
                        Vertical-positive
                      </MenuItem>
                      <MenuItem value="Vertical-negative">
                        Vertical-negative
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    style={{
                      width: "150px",
                      fontSize: "12px",
                      borderRadius: "10px",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      // marginBottom: "20px",
                    }}
                    onClick={() => addDimensionHandler()}
                    variant="contained"
                  >
                    Add
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // overflowY: "auto",
                    // flexDirection: "column",
                  }}
                >
                  {dimensions.length > 0 && (
                    <div className="table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Length (inch)</th>
                            <th>Orientation</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <div className="scrollable-content">
                            {dimensions.map((data, index) => (
                              <tr key={index}>
                                <td>{data.title}</td>
                                <td>{data.length}</td>
                                <td>{data.orientation}</td>
                                <td>
                                  <IconButton
                                    onClick={() => deleteHandler(index)}
                                    style={{ marginLeft: "auto" }}
                                    sx={{
                                      // position: "absolute",
                                      // right: 8,
                                      // top: 3,
                                      height: "46px",
                                      width: "46px",
                                      color: (theme) => theme.palette.grey[500],
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleEditOpen(data, index)}
                                    sx={{
                                      // position: "absolute",
                                      // right: 8,
                                      // top: 3,
                                      height: "46px",
                                      width: "46px",
                                      color: (theme) => theme.palette.grey[500],
                                    }}
                                    style={{ width: "65px" }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))}
                          </div>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </div>
            <div style={{ width: "50%" }}>
              <canvas
                ref={canvasRef}
                style={{
                  border: "1px solid gray",
                  // margin: "15px",
                  padding: "15px",
                }}
                width={700}
                height={500}
              />
            </div>
          </div>
        </DialogContent>

        {/* </div> */}
        {showError && (
          <p
            className="error-text"
            style={{ position: "relative", left: "75%" }}
          >
            {errorMessage}
          </p>
        )}

        <DialogActions>
          {!isLoading ? (
            <Button
              style={{
                width: "150px",
                fontSize: "12px",
                borderRadius: "10px",
                backgroundColor: "#1976d2",
                color: "#fff",
                marginBottom: "20px",
              }}
              variant="contained"
              onClick={handleCreate}
            >
              Create
            </Button>
          ) : (
            <Button
              style={{
                width: "150px",
                fontSize: "12px",
                borderRadius: "10px",
                backgroundColor: "#1976d2",
                color: "#fff",
                marginBottom: "20px",
              }}
              variant="contained"
              // onClick={handleCreate}
            >
              Creating...
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openEdit} fullWidth maxWidth="md">
        <DialogTitle>
          Edit Profile
          <IconButton
            aria-label="close"
            onClick={handleEditClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 3,
              height: "46px",
              width: "46px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", gap: "10px" }}>
          <DialogContentText>
            {/* <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Length</th>
                  <th>Orientation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedRowData?.title}</td>
                  <td>{selectedRowData?.length}</td>
                  <td>{selectedRowData?.orientation}</td>
                </tr>
              </tbody>
            </table>
          </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                gap: "20px",
              }}
            >
              <FormControl
                sx={{
                  width: "30%",
                  marginRight: "5px",
                }}
              >
                <InputLabel>Title</InputLabel>
                <OutlinedInput
                  label="Title"
                  value={selectedRowData?.title}
                  autoComplete="off"
                  onChange={(e) =>
                    setSelectedRowData({
                      ...selectedRowData,
                      title: e.target.value,
                    })
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              <FormControl
                sx={{
                  width: "30%",
                  marginRight: "5px",
                }}
              >
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={selectedRowData?.length}
                  autoComplete="off"
                  onChange={(e) =>
                    setSelectedRowData({
                      ...selectedRowData,
                      length: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">inch</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              <FormControl
                key={Math.random()}
                sx={{
                  width: "56%",
                }}
              >
                <InputLabel>Orientation</InputLabel>
                <Select
                  value={selectedRowData?.orientation}
                  onChange={(e) =>
                    setSelectedRowData({
                      ...selectedRowData,
                      orientation: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Horizontal-negative">
                    Horizontal-negative
                  </MenuItem>
                  <MenuItem value="Horizontal-positive">
                    Horizontal-positive
                  </MenuItem>
                  <MenuItem value="Vertical-positive">
                    Vertical-positive
                  </MenuItem>
                  <MenuItem value="Vertical-negative">
                    Vertical-negative
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              width: "180px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModal} fullWidth maxWidth="xs">
        <DialogTitle>Confirm!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", gap: "20px", padding: "20px" }}
        >
          {!deleteLoading ? (
            <>
              <Button onClick={handleCloseModal} variant="contained">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDelete(deletedId);
                }}
                variant="contained"
              >
                Delete
              </Button>
            </>
          ) : (
            <Button variant="contained">Deleting...</Button>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        style={{
          marginLeft: "650px",
          marginBottom: "435px",
          top: "0px",
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Profile is deleted successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;
