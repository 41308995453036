// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-dplwbx-MuiPickersCalendarHeader-label{
font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Tables/CustomDatePicker.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf","sourcesContent":[".css-dplwbx-MuiPickersCalendarHeader-label{\nfont-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
