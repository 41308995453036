import { useCallback, useRef, useEffect } from "react";

export default function useIntersectionObserver(callback, deps = []) {
  const observer = useRef(null);

  const ref = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback(entries[0]);
        }
      });

      if (node) observer.current.observe(node);
    },
    [callback, ...deps]
  );

  useEffect(() => {
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return ref;
}
