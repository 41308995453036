// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/components/Card.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C","sourcesContent":[".card {\n  border-radius: 12px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
