// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: flex;
  flex-direction: column;
}

.profile {
  border: 2px solid #ffc409;
  border-radius: 50%;
}

.logo_image {
  margin: 0px 0px;
  padding: 0px 10px 0px 10px;
}
.css-h4y409-MuiList-root {
  padding: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Admin/AdminPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".home {\n  display: flex;\n  flex-direction: column;\n}\n\n.profile {\n  border: 2px solid #ffc409;\n  border-radius: 50%;\n}\n\n.logo_image {\n  margin: 0px 0px;\n  padding: 0px 10px 0px 10px;\n}\n.css-h4y409-MuiList-root {\n  padding: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
