import React, { forwardRef, useEffect, useState, useRef } from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, TablePagination } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { ThemeProvider, createTheme } from "@mui/material";
import AppBarCommon from "../../AppBarCommon";
import EventsModal from "../../Events/EventsModal";
import "react-lazy-load-image-component/src/effects/blur.css";
import ViewFramesModal from "./ViewFramesModal";
import "./ViewEventsTable.css";
import CustomDatePicker from "./CustomDatePicker";
import RealEventsModal from "../../Events/RealEventsModal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import useAuth from "../../../hooks/useAuth";
import { ClickAwayListener } from "@mui/material";
import Chip from "@mui/material/Chip";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutlineIcon {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterAltIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeftIcon {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardIcon {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <RemoveIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumnIcon {...props} ref={ref} />
  )),
};

const ViewEventsTable = (props) => {
  const { isHomePage, hasAdminRole } = props;
  const axiosPrivate = useAxiosPrivate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const defaultMaterialTheme = createTheme();
  const [filter, setFilter] = useState(false);
  const [openRealModal, setRealOpenModal] = useState(false);
  const tableRef = useRef();
  const [eventsTotalCount, setEventsTotalCount] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [pageNumber, setPagenumber] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [muiTableKey, setMuiTableKey] = useState(0);
  const [hasFilterValues, setHasFilterValues] = useState(false);
  const [tagActive, setTagActive] = useState(false);
  const [tagID, setTagID] = useState("");
  const [isTagged, setIsTagged] = useState(false);
  const { auth, setAuth } = useAuth();
  const [bottomDrop, setBottomDrop] = useState(false);
  const [tags, setTags] = useState([]);
  const [openRealEventsModal, setRealEventsOpenModal] = useState(false);
  const [customPrediction, setCustomPrediction] = useState("");
  const [customTags, setCustomTags] = useState([]);
  const [otherPrediction, setOtherPrediction] = useState([]);
  const [buttonEnable, setButtonEnable] = useState(true);
  const [filterTableData, setFilterTableData] = useState({});
  const [filterActive, setFilterActive] = useState(false);
  const [value, setValue] = useState(dayjs(new Date()));
  const [modalMinimize, setModalMinimize] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [eventData, setEventData] = useState([]);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleDateChange = (newValue) => {
    setValue(newValue);
    const filterValue = convert(newValue.toLocaleString());

    let filterUrl = `/defect-inspection/v1/events?filter_created_date:eq=${filterValue}`;

    getEvents(filterUrl).then((data) => {
      setFilterTableData(data);
      setFilterActive(true);
    });
  };

  const handleCloseRealModal = () => {
    setRealOpenModal(false);
  };
  const handleCloseRealEventsModal = () => {
    setRealEventsOpenModal(false);
  };

  let url;

  const viewRealTime = () => {
    setRealOpenModal(true);
  };
  const viewRealTimeEvents = () => {
    setRealEventsOpenModal(true);
  };

  const handleOpenModal = (data) => {
    setOpenModal(true);
    setSelectedImage(data);
    setEventData(data);
  };

  useEffect(() => {
    setMuiTableKey(muiTableKey + 1);
  }, [filterActive, filterTableData?.events]);

  useEffect(() => {
    if (!filter) {
      setFilterTableData([]);
      setFilterActive(false);
    }
  }, [filter]);

  const handleChange = () => {
    setFilter(!filter);
    setValue(dayjs(new Date()));
    setHasFilterValues(false);
  };

  useEffect(() => {
    setButtonEnable(false);
  }, [customTags]);

  let interval;

  const handeleTableReload = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  useEffect(() => {
    if (eventsTotalCount) {
      handeleTableReload();
    }
  }, [eventsTotalCount]);

  useEffect(() => {
    interval = setInterval(() => {
      let reloadUrl = `/defect-inspection/v1/events?page=1&limit=10`;
      getEvents(reloadUrl).then((data) => {
        if (data.events.length > 0) {
          let latest_event = data.events[0];
          if (tableRef.current.state.data.length > 0) {
            let event = JSON.parse(
              JSON.stringify(tableRef.current.state.data[0])
            );
            delete event.tableData;

            if (JSON.stringify(event) != JSON.stringify(latest_event)) {
              console.log("Reloading..");
              handeleTableReload();
            }
          }
        }

        // setEventsTotalCount(data.page_metadata.total_count);
      });
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setShowImage(false);
  }, [pageNumber, orderBy]);

  const controller = new AbortController();
  const getEvents = async (url) => {
    try {
      const userResponse = await axiosPrivate.get(url, {
        signal: controller.signal,
      });
      return userResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClearFilter = () => {
    if (filterActive) {
      setMuiTableKey(muiTableKey + 1);
    } // for clearing the filter values
    setFilter(false);
    setHasFilterValues(false);
  };

  const makeDateReadable = (stringValue) => {
    const newDate = new Date(stringValue + " UTC");
    const finalResult = newDate.toLocaleString();
    return finalResult;
  };

  const handleTagin = (e, data) => {
    if (e.screenY > 465) {
      setBottomDrop(true);
    } else {
      setBottomDrop(false);
    }
    setTagActive(true);
    setTags(data.tags);
    setCustomTags(data.tags);
    setTagID(data.event_id);
    const otherPredictioninTag = data.tags?.filter(
      (item) => item != "wrongprediction"
    );
    setOtherPrediction(otherPredictioninTag);
    if (data.tags.length) {
      data.tags.map((item) => {
        if (item == "wrongprediction") setIsTagged(true);
      });
    } else {
      setIsTagged(false);
    }
  };

  const handleMinimizeBtn = (val) => {
    setModalMinimize(val);
  };

  const handleCheck = (data) => {
    const wronginTagsIndex = customTags.findIndex(
      (item) => item === "wrongprediction"
    );
    if (wronginTagsIndex == -1) {
      customTags.unshift("wrongprediction");
      setIsTagged(true);
    } else {
      let customTagsTemp = customTags.filter((item) => {
        return item !== "wrongprediction";
      });
      setCustomTags(customTagsTemp);
      setIsTagged(false);
    }
  };

  const tagSubmit = async (data) => {
    const tagsArr = customTags;
    const access_token = auth?.access_token;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let body = {
      tags: tagsArr,
    };

    const response = await axiosPrivate.post(
      `/defect-inspection/v1/events/${data.event_id}/add_tags`,
      body,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Time-Zone": `${timeZone}`,
        },
        withCredentials: false,
      }
    );
    setAuth((prev) => {
      return {
        ...prev,
        response: response.data,
      };
    });
    setTagActive(false);
    setTags([]);
    setCustomTags([]);
    handeleTableReload();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (customPrediction.length) {
      customTags.push(customPrediction);
      setCustomPrediction("");
    }
  };

  const handleCloseActiveTag = () => {
    setTagActive(false);
    setTags([]);
    setCustomTags([]);
    setCustomPrediction("");
    setOtherPrediction([]);
  };

  const handleChipDelete = (item, index) => {
    setCustomTags((customTags) =>
      customTags.filter((zitem, zindex) => zindex != index)
    );
  };

  const columns = [
    { title: "Event Id", field: "event_id", sorting: false, filtering: false },
    {
      title: "Work Piece Id",
      field: "workpiece_id",
      sorting: false,
      filtering: false,
    },
    {
      title: "Order Number",
      field: "order_number",
      sorting: false,
      filtering: false,
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      filtering: false,
    },

    {
      title: "Captured Image",
      field: "event.image",
      sorting: false,
      filtering: false,
      render: (rowData) => (
        <>
          <div
            variant="contained"
            type="button"
            style={{
              // background: "rgb(55 48 224)",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenModal(rowData);
            }}
          >
            <div className="image-container">
              <img
                onLoad={() => setShowImage(true)}
                key={rowData.index}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={rowData.image}
              ></img>
              {!showImage && <div className="image-placeholder"></div>}
            </div>
            {/* <div className="image-container">
              <img
                onLoad={() => setShowImage(true)}
                key={rowData.index}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={rowData.corner1_image2_thumbnail}
              ></img>
              {!showImage && <div className="image-placeholder"></div>}
            </div>
            <div className="image-container">
              <img
                onLoad={() => setShowImage(true)}
                key={rowData.index}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={rowData.corner2_image1_thumbnail}
              ></img>
              {!showImage && <div className="image-placeholder"></div>}
            </div>
            <div className="image-container">
              <img
                onLoad={() => setShowImage(true)}
                key={rowData.index}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={rowData.corner2_image2_thumbnail}
              ></img>
              {!showImage && <div className="image-placeholder"></div>}
            </div> */}
          </div>
          {openModal && (
            <ViewFramesModal
              selectedImage={selectedImage}
              openModal={openModal}
              handleClose={handleCloseModal}
              eventData={eventData}
            />
          )}
        </>
      ),
    },
    {
      title: "Created Date",
      field: "created_date",
      filtering: false,
      // type: "date",
      // dateSetting: { locale: "en-GB" },
      // filterComponent: (props) => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     {console.log("propsss", props)}
      //     <CustomDatePicker {...props} />{" "}
      //     <div
      //       style={{ marginLeft: "10px", cursor: "pointer", color: "red" }}
      //       onClick={handleClearFilter}
      //       tooltip="Close Filter"
      //     >
      //       <ClearIcon />
      //     </div>
      //   </div>
      // ),
      render: (rowData) => makeDateReadable(rowData.created_date),
    },
    {
      title: "Actions",
      field: "",
      render: (rowData) => (
        <>
          <div style={{ position: "relative" }}>
            <LocalOfferIcon
              onClick={(e) => {
                handleTagin(e, rowData);
              }}
              style={{
                cursor: "pointer",
                color:
                  tagActive && rowData.event_id === tagID
                    ? "#656363"
                    : rowData.tags.length
                    ? "#70ca70"
                    : "#6462627a",
              }}
            ></LocalOfferIcon>
            {tagActive && rowData.event_id === tagID && (
              <ClickAwayListener onClickAway={() => handleCloseActiveTag()}>
                <div
                  style={{
                    position: "absolute",
                    width: "200px",
                    minHeight: "120px",
                    zIndex: 1,
                    borderRadius: "6px",
                    right: "40px",
                    background: "white",
                    bottom: bottomDrop ? "40px" : "",
                    padding: "12px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Chip
                    icon={RemoveIcon}
                    style={{ margin: "4px" }}
                    label="Wrong Prediction"
                    color={isTagged ? "primary" : "default"}
                    onClick={() => handleCheck(rowData)}
                  />
                  {/* {otherPrediction.length != 0 && (
                    <>
                      {otherPrediction.map((item, index) => {
                        return (
                          <Chip
                            onDelete={() => alert("hi")}
                            style={{ margin: "4px" }}
                            key={index}
                            label={item}
                            color="primary"
                          ></Chip>
                        );
                      })}
                    </>
                  )} */}
                  {customTags.length != 0 && (
                    <>
                      {customTags.map((item, index) => {
                        if (item !== "wrongprediction")
                          return (
                            <Chip
                              onDelete={() => handleChipDelete(item, index)}
                              style={{ margin: "4px" }}
                              key={index}
                              label={item}
                              color="primary"
                            ></Chip>
                          );

                        return null;
                      })}
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    <form
                      onSubmit={(e) => handleFormSubmit(e)}
                      style={{ width: "90%" }}
                    >
                      <input
                        placeholder="Add Custom Tag"
                        style={{ height: "34px", margin: "4px", width: "96%" }}
                        onChange={(e) => setCustomPrediction(e.target.value)}
                        value={customPrediction}
                      ></input>
                    </form>
                    <FileUploadIcon
                      onClick={() => tagSubmit(rowData)}
                      style={{
                        height: "32px",
                        width: "32px",
                        marginLeft: "4px",
                        color: "#70ca70",
                        cursor: "pointer",
                      }}
                    ></FileUploadIcon>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {!isHomePage && (
        <>
          <AppBarCommon
            name="Events"
            viewRealTime={viewRealTime}
            viewRealTimeEvents={viewRealTimeEvents}
            viewBtn={true}
            miniMize={modalMinimize}
            viewEvents={true}
          />
          <EventsModal
            openModal={openRealEventsModal}
            handleClose={handleCloseRealEventsModal}
          ></EventsModal>
          <RealEventsModal
            openModal={openRealModal}
            handleClose={handleCloseRealModal}
            handleMinimize={handleMinimizeBtn}
          ></RealEventsModal>
        </>
      )}

      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ margin: "24px", position: "relative" }}
          title=""
          key={muiTableKey}
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          options={{
            debounceInterval: 700,
            padding: "dense",
            // filtering: false,
            pageSize: pageSize,
            sorting: true,
            thirdSortClick: false,
            searchFieldStyle: {
              minWidth: "420px",
              paddingRight: "30px",
            },
          }}
          actions={[
            {
              icon: () => (
                <FilterAltIcon
                  style={{
                    color: filter ? "green" : "grey",
                  }}
                />
              ),
              onClick: () => handleChange(),
              tooltip: "Filter by Created Date",
              isFreeAction: true,
            },
          ]}
          data={(query) =>
            new Promise((resolve, _reject) => {
              url = "/defect-inspection/v1/events?";

              //pagination
              url += `page=${query.page + 1}`;
              url += `&limit=${query.pageSize}`;

              if (query.search) {
                url += `&search=${query.search}`;
                console.log("search");
              }

              //sorting
              if (query.orderBy) {
                url += `&sort_by=${query.orderBy.field}&order_by=${query.orderDirection}`;
              }

              setPagenumber(query.page + 1); //For clearing the img thumbnail when page changes
              //search
              setOrderBy(query.orderBy);
              setPageSize(query.pageSize);

              //filtering
              // if (query.filters.length) {
              //   const filter = query.filters.map((filter) => {
              //     return `&filter_${filter.column.field}:eq${filter.operator}${filter.value}`;
              //   });
              //   url += filter;
              //   setHasFilterValues(true);
              // }

              const getData = () => {
                getEvents(url).then((data) => {
                  setEventsTotalCount(data.page_metadata.total_count);
                  resolve({
                    data: filterActive ? filterTableData.events : data.events,
                    page: query.page,
                    totalCount: filterActive
                      ? filterTableData.page_metadata.total_count
                      : data.page_metadata.total_count,
                  });
                });
              };
              getData();
            })
          }
          components={{
            // Pagination: (data) => (
            //   <>
            //     {isHomePage ? (
            //       <div
            //         style={{
            //           display: "flex",
            //           justifyContent: "center",
            //           height: "40px",
            //           width: "100%",
            //           alignItems: "center",
            //         }}
            //       >
            //         <Button
            //           onClick={() => {
            //             window.location.pathname = hasAdminRole
            //               ? "/admin/add-events"
            //               : "/member/events";
            //           }}
            //           style={{ fontWeight: 600, fontSize: "16px" }}
            //         >
            //           View More
            //         </Button>
            //       </div>
            //     ) : (
            //       <TablePagination {...data} />
            //     )}
            //   </>
            // ),
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <MTableToolbar {...props} />
                {filter && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "12px",
                      marginRight: "24px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={1}>
                          <DesktopDatePicker
                            PopperProps={{
                              sx: {
                                "& .MuiPickersCalendarHeader-labelContainer ": {
                                  maxHeight: "none !important",
                                  fontSize: "14px",
                                },
                              },
                            }}
                            label="Filter by Created Date"
                            inputFormat="MM/DD/YYYY"
                            value={value}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={handleClearFilter}
                      tooltip="Close Filter"
                    >
                      <ClearIcon />
                    </div>
                  </div>
                )}
              </div>
            ),
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Search by Work Piece ID / Order Number",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ViewEventsTable;
