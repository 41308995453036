// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#recent-events-scroll-container::-webkit-scrollbar {
  width: 6px;
}
  
#recent-events-scroll-container::-webkit-scrollbar-track {
  background-color: #d8d7d7;
  width: 3px;
  border-radius: 100px;
}
  
#recent-events-scroll-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 100px;
  border: 2px solid #d8d7d7;
}

.recentEvents-list{
  cursor: pointer;
  text-align: center;
  list-style: none;
  padding: 10px;
  border-bottom: 1px ridge #dad6d6;
  font-size: 15px;
}
.recentEvents-list-selected{
  cursor: pointer;
  text-align: center;
  list-style: none;
  padding: 10px;
  border-bottom: 1px ridge #dad6d6;
  font-size: 15px;
  background-color: aqua;
}
.recentEvents-list:hover{
  background-color: rgb(182, 220, 238);
}`, "",{"version":3,"sources":["webpack://./src/components/Events/EventsModal.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,0CAA0C;EAC1C,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,gCAAgC;EAChC,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,oCAAoC;AACtC","sourcesContent":["#recent-events-scroll-container::-webkit-scrollbar {\n  width: 6px;\n}\n  \n#recent-events-scroll-container::-webkit-scrollbar-track {\n  background-color: #d8d7d7;\n  width: 3px;\n  border-radius: 100px;\n}\n  \n#recent-events-scroll-container::-webkit-scrollbar-thumb {\n  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);\n  border-radius: 100px;\n  border: 2px solid #d8d7d7;\n}\n\n.recentEvents-list{\n  cursor: pointer;\n  text-align: center;\n  list-style: none;\n  padding: 10px;\n  border-bottom: 1px ridge #dad6d6;\n  font-size: 15px;\n}\n.recentEvents-list-selected{\n  cursor: pointer;\n  text-align: center;\n  list-style: none;\n  padding: 10px;\n  border-bottom: 1px ridge #dad6d6;\n  font-size: 15px;\n  background-color: aqua;\n}\n.recentEvents-list:hover{\n  background-color: rgb(182, 220, 238);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
