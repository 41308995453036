// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  width: 26px;
  height: 26px;
  position: relative;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 1px solid rgb(150, 147, 147);
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Tables/ViewEventsTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".image-container {\n  width: 26px;\n  height: 26px;\n  position: relative;\n}\n\n.image-placeholder {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  border: 1px solid rgb(150, 147, 147);\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
