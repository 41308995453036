import "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./CustomDatePicker.css";

const CustomDatePicker = (props) => {
  const [datePickerValue, setDatePickerValue] = useState(dayjs(new Date()));

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          value={datePickerValue}
          onChange={(newValue) => {
            console.log("Date picker value: ", newValue.toLocaleString());
            console.log(props.columnDef.tableData.id);

            setDatePickerValue(newValue);
            props.onFilterChanged(
              props.columnDef.tableData.id,
              convert(newValue.toLocaleString())
            );
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
