import { MONTHS } from "./constants";

export const formatTime = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let secs = Math.floor(seconds % 60);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (secs < 10) {
    secs = "0" + secs;
  }

  return minutes + ":" + secs;
};


export const formatNthNumber = (number) => {
  if (number > 3 && number < 21) return number + "th";
  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};

export const isIOSDevice = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

  return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
};

export const makeDateReadable = (stringValue) => {
  const day = new Date(stringValue);
  const m = MONTHS;
  const str_op =
    day.getDate() + " " + m[day.getMonth()] + "," + day.getFullYear();
  return str_op;
};

export const getFormDataSize = (formData) => {
  let totalSize = 0;

  for (let entry of formData.entries()) {
      const [key, value] = entry;

      // Size of the key in bytes
      const keySize = new TextEncoder().encode(key).length;
      totalSize += keySize;

      // Size of the value in bytes
      if (value instanceof File) {
          // If value is a File, use its size property
          totalSize += value.size;
      } else {
          // If value is a string, encode it to get the byte length
          const valueSize = new TextEncoder().encode(value).length;
          totalSize += valueSize;
      }
  }

  return totalSize;
}

export const copyTextToClipboard = async (textToCopy, callback) => {
  // Create a text area element to hold the text temporarily
  const textArea = document.createElement("textarea");
  textArea.value = `${textToCopy}`;

  // Append the text area to the DOM (you can make it invisible with CSS)
  document.body.appendChild(textArea);

  // Select the text in the text area
  textArea.select();
  
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      textArea.remove();
      callback();
    })
    .catch((error) => {
      console.error("Unable to copy text to clipboard:", error);
    });
};

