// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  /* background-image: url("yotta_bgi.png"); */
}

* {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,4CAA4C;AAC9C;;AAEA;EACE,SAAS;AACX","sourcesContent":[".fill-window {\n  height: 100%;\n  position: absolute;\n  left: 0;\n  width: 100%;\n  overflow: hidden;\n  /* background-image: url(\"yotta_bgi.png\"); */\n}\n\n* {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
