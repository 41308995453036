import React, { useRef, useState } from "react";
import { AppBar, IconButton, InputAdornment, TextField } from "@mui/material";
import { Button, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateRangeIcon from "@mui/icons-material/DateRange";

const AppBarCommon = (props) => {
  const { name, searchTerm, setSearchTerm, selectedDate, setSelectedDate } =
    props;
  const navigate = useNavigate();
  const { mobileOpen } = useOutletContext();

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    //  logic here to filter the events
  };

  const calendarIconRef = useRef(null);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    setOpenDatePicker(false);
  };

  return (
    <>
      <AppBar
        position=""
        elevation={1}
        sx={{
          // width: { sm: `calc(100% - ${mobileOpen ? "216px" : "64px"})` },
          // ml: { sm: `${mobileOpen ? "216px" : "64px"}` },
          boxShadow: "none",
          justifyContent: "center",
        }}
        style={{ backgroundColor: "#eaedeb", margin: "1rem 0px" }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle} // Call handleDrawerToggle from AdminPage
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.backButton && (
              <Button style={{ width: "56px" }} onClick={() => navigate(-1)}>
                <ArrowBackIcon
                  style={{ color: "black", marginRight: "20px" }}
                />
              </Button>
            )}
            <Typography
              style={{
                fontFamily: "Work Sans",
                color: "rgb(94 89 89)",
                fontWeight: 600,
                fontSize: "2rem",
                position: "relative",
                marginRight: "50px",
              }}
              noWrap
              component="div"
            >
              {name}
              <span
                style={{
                  content: "''",
                  display: "block",
                  width: "40%",
                  height: "5px",
                  backgroundColor: "#239BB9",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              />
            </Typography>
            {props.viewEvents && (
              <>
                <TextField
                  variant="standard"
                  placeholder="Search by workpiece Id or Order number"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#007c92" }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Work Sans", fontSize: 14 },
                  }}
                  inputProps={{
                    sx: {
                      fontFamily: "Work Sans",
                      fontSize: 14,
                      "&::placeholder": {
                        color: "#14285A4D",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    },
                  }}
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    backgroundColor: "#f6fbfc",
                    padding: "10px 20px",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                    width: "360px",
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      value={selectedDate}
                      onChange={handleDateChange}
                      sx={{
                        display: "none",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{
                            display: "none",
                          }}
                        />
                      )}
                      slotProps={{
                        popper: {
                          placement: "bottom",
                          anchorEl: calendarIconRef.current,
                        },
                      }}
                    />

                    <InputAdornment
                      position="end"
                      onClick={() => setOpenDatePicker(true)}
                      ref={calendarIconRef}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                        ref={calendarIconRef}
                        onClick={() => setOpenDatePicker(true)}
                      >
                        <DateRangeIcon
                          style={{ cursor: "pointer", color: "#007c92" }}
                        />
                      </div>
                    </InputAdornment>
                  </div>
                </LocalizationProvider>
              </>
            )}
          </div>
          {props.viewBtn && (
            <div style={{ display: "flex" }}>
              {/* <Button
                style={{
                  width: "150px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
                onClick={props.viewRealTimeEvents}
                variant="contained"
              >
                View Real Time Events
              </Button> */}
              <Button
                style={
                  props.miniMize
                    ? {
                        marginLeft: "12px",
                        width: "150px",
                        fontSize: "12px",
                        borderRadius: "10px",
                        backgroundColor: "#49758d",
                        color: "#fff",
                        border: "1px solid #49758d",
                        fontFamily: "Work Sans",
                      }
                    : {
                        marginLeft: "12px",
                        width: "150px",
                        fontSize: "12px",
                        borderRadius: "10px",
                        backgroundColor: "#49758d",
                        color: "#fff",
                        border: "1px solid #49758d",
                        fontFamily: "Work Sans",
                      }
                }
                onClick={props.viewRealTime}
                variant="contained"
              >
                {props.miniMize ? "Continue With Order" : "New Order"}
              </Button>
            </div>
          )}
          {props.registerDevice && (
            <Button
              style={{
                width: "150px",
                fontSize: "12px",
                borderRadius: "10px",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
              onClick={() => navigate("register-device")}
              variant="contained"
            >
              Register New Device
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppBarCommon;
