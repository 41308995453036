// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  font-family: "Work Sans";
}

/* body {
  font-family: "Open Sans", "Lato", sans-serif;
  margin: 0;
} */

body {
  background-color: #eaedeb !important;
}

.error-text {
  color: #b40e0e;
  padding-left: 12px;
}

::-webkit-scrollbar {
  display: none;
}

table,
th,
td {
  border: 1px solid lightgray;
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;;;GAGG;;AAEH;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;;;EAGE,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":["* {\n  box-sizing: border-box;\n  font-family: \"Work Sans\";\n}\n\n/* body {\n  font-family: \"Open Sans\", \"Lato\", sans-serif;\n  margin: 0;\n} */\n\nbody {\n  background-color: #eaedeb !important;\n}\n\n.error-text {\n  color: #b40e0e;\n  padding-left: 12px;\n}\n\n::-webkit-scrollbar {\n  display: none;\n}\n\ntable,\nth,\ntd {\n  border: 1px solid lightgray;\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
