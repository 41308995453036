import axios from "axios";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export default axios.create({
  baseURL: BASE_URL,
});

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", "Time-Zone": `${timeZone}` },
  withCredentials: false,
});
